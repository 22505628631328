import { useEffect, useState } from "react";
import { useDebounce } from "../../services/useDebounce";
import { api } from "../../services/api";
import { ShowToast } from "../../components/Toast";
const LIMIT = 15;

function useActivities({ type }: { type: string }) {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    // page: 1,
    keyword: "",
    // status: "active",
    limit: LIMIT,
    sort_by: "",
    sort_order: "",
    type: "all",
    filter_by: "",
    filter_value: [],
  });

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);

  const fetchPlaces = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      const { data, total } = await api.getActivities({
        ...params,
        ...pageInfo,
      });
      setIsLoading(false);
      // console.log("data", data, pageInfo, params);
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) => prev.filter((item) => item.id !== refetchId));
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const { data } = await api.getActivities({
            ...params,
            page: i,
          });
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 1) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (err) {
      setIsLoading(false);
      ShowToast({
        type: "error",
        message: "Failed to fetch places",
      });
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchPlaces(debouncedFilters, pagination, refetchId, isDeleted, refetchAll);
  };

  const loadMore = () => {
    // update page only we have data
    if (data?.length > 0) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchPlaces(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    // console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setPagination((prev) => ({ ...prev, page: 1 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, type }));
  }, [type]);

  useEffect(() => {
    fetchPlaces(debouncedFilters);
  }, [debouncedFilters]);

  const activities: any[] = data || [];
  return {
    activities,
    totalPages: Math.ceil(total / LIMIT),
    total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    loadMore,
    refetch,
    updateFilters: (updates) => handleFilters(updates),
    reset: () => {
      setData([]);
      setState({
        page: 1,
        keyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
        type,
      });
    },
  };
}

export default useActivities;
