import { createFilter } from "react-select";
import apiClient from "./axios";

export const api = {
  login: (data: any) => {
    return apiClient.post("/admin/auth/login", data);
  },
  getAdminDetails: () => {
    return apiClient.get("/admin/me");
  },
  getDashboard: () => {
    return apiClient.post("/admin/dashboard").then((res) => res.data);
  },
  getDashboardCharts: ({ type }) => {
    return apiClient
      .get(`/admin/dashboard-chart?type=${type}`)
      .then((res) => res.data);
  },
  getUsers: (data: any) => {
    let url = `/admin/users?page=${data.page}&limit=${data.limit}&type=${data.type}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    if (data.is_plus_user) {
      url += `&is_plus_user=true`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getPlaces: (data: any) => {
    let url = `/admin/places?page=${data.page}&limit=${data.limit}&type=${data.type}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getBlockedUsersByAdmin: (data: any) => {
    let url = `/admin/user/blocked-by-admin?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  updateUser: (data: any) => {
    ///admin/user/profile/update
    return apiClient.put("/admin/user/profile/update", data);
  },
  updatePlace: (data: any) => {
    ///admin/place/update
    return apiClient.put("/admin/place/update", data);
  },
  addPlace: (data: any) => {
    ///admin/place/update
    return apiClient.put("/admin/place/update", data);
  },
  getPlacesSuggestions: (data: any) => {
    // admin/place-suggestion
    let url = `/admin/place-suggestion`;
    return apiClient
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data);
  },
  deletePlaceSuggestion: (data: any) => {
    // admin/place-add-status/update
    let url = `/admin/place-add-status/update`;
    return apiClient.delete(url, {
      data,
    });
  },
  addPlaceUrls: (data: any) => {
    return apiClient.post("admin/place/data", data);
  },
  getPlaceHistory: (data: any) => {
    //admin/place-add/history
    const params: any = {
      filter: data.type,
      search: data.keyword,
    };
    if (data.sort_order && data.sort_by) {
      params.sort_order = data.sort_order;
      params.sort_by = data.sort_by;
    }
    return apiClient
      .post("admin/place-add/history", params)
      .then((res) => res.data);
  },
  getPlace: (id: string) => {
    ///admin/place/{place_id}
    return apiClient.get(`/admin/place/${id}`).then((res) => res.data);
  },
  getPlaceDetails: (id: string) => {
    ///admin/place/{place_id}
    return apiClient.get(`/admin/place-details/${id}`).then((res) => res.data);
  },
  blockUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/block-user", data);
  },

  unblockUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/unblock-user", data);
  },
  deleteUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/delete-user", data);
  },
  deletePlace: (data: { id: string }) => {
    return apiClient.post("/admin/place/delete-place", data);
  },
  getUserInfo: (userId: string) => {
    return apiClient
      .get(`/admin/user-info?id=${userId}`)
      .then((res) => res.data);
  },
  getUserMatches: (userId: string) => {
    let url = `/admin/user?id=${userId}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getEmptyStates: (params: any) => {
    return apiClient
      .get(
        `/admin/reveals/empty-states?page=${params.page}&limit=${params.limit}`
      )
      .then((res) => res.data);
  },
  addEmptyState: (data: any) => {
    return apiClient.post("/admin/reveals/empty-states", data);
  },
  updateEmptyState: (data: any) => {
    return apiClient.put("/admin/reveals/empty-states", data);
  },
  deleteEmptyState: (id: string) => {
    return apiClient.delete(`/admin/reveals/empty-states/${id}`);
  },
  getRevealDetails: (id: string) => {
    return apiClient.get(`/admin/reveals/${id}`).then((res) => res.data);
  },
  getCategories: (params: any) => {
    // /admin/categories

    let url = `/admin/categories?page=${params.page}&limit=${params.limit}`;
    if (params.sort_order) {
      url += `&sort_order=${params.sort_order}`;
    }
    if (params.search) {
      url += `&search=${params.search}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  addCategory: (data: any) => {
    return apiClient.post("/admin/categories/create", data);
  },
  updateCategory: (data: any) => {
    return apiClient.put("/admin/categories/update", data);
  },
  deleteCategory: (id: string) => {
    return apiClient.delete(`/admin/categories/delete/${id}`);
  },
  addQuestion: (data: any) => {
    return apiClient.post("/admin/reveals/categories/question", data);
  },
  updateQuestion: (data: any) => {
    return apiClient.put("/admin/reveals/categories/question", data);
  },
  deleteQuestion: (id: string) => {
    return apiClient.delete(`/admin/reveals/categories/question/${id}`);
  },
  getPlaceCategories: ({
    page = 1,
    limit = 10000,
    sort_order = "",
    search = "",
  }: {
    page?: number;
    limit?: number;
    sort_order?: string;
    search?: string;
  }) => {
    let url = `/admin/place/categories?page=${page}&limit=${limit}`;
    if (sort_order) {
      url += `&sort_order=${sort_order}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getPlaceActivities: () => {
    return apiClient.get("/admin/place/activities").then((res) => res.data);
  },
  getActivity: (activityId: string) => {
    let url = `/admin/activities/${activityId}`;
    return apiClient.get(url).then((res) => res.data);
  },
  createActivity: (data: any, autoSave = false) => {
    let url = `/admin/activities/create`;
    if (autoSave) {
      url = `${url}?autoSave=true`;
    }
    return apiClient.post(url, data);
  },
  updateActivity: (data: any, autoSave = false) => {
    let url = "/admin/activities/update";
    if (autoSave) {
      url = `${url}?autoSave=true`;
    }
    return apiClient.put(url, data);
  },
  deleteActivity: (id: string) => {
    const url = "/admin/activities/delete";
    return apiClient.delete(url, {
      data: {
        id: id.toString(),
      },
    });
  },
  getActivities: (data: any) => {
    let url = `/admin/activities?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.filter_value?.length > 0) {
      if (data.filter_by) {
        url += `&filter=${data.filter_by}`;
      }
      url += `&value=${(data.filter_value || []).join(",")}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getImageUpload: (data: {
    folder: string;
    file_name: string;
    content_type: string;
  }) => {
    return apiClient
      .post("/general/image/upload", data)
      .then((res) => res.data);
  },
  getFilters: (data: any) => {
    return apiClient
      .get(`/admin/app-filters?type=${data.type}`)
      .then((res) => res.data);
  },
  createFilter: (data: any) => {
    const url = "/admin/app-filters/create";
    return apiClient.post(url, data);
  },
  updateFilter: (data: any) => {
    const url = "/admin/app-filters/update";
    return apiClient.put(url, data);
  },
  deleteFilter: (id: string, type: string) => {
    const url = `/admin/app-filters/delete/${id}/${type}`;
    return apiClient.delete(url);
  },
  downloadImage: (url: string) => {
    return apiClient
      .get(`/general/download-image?downloadUrl=${url}`, {
        responseType: "blob",
      })
      .then((res) => res.data);
  },
  // /general/image/upload
};
